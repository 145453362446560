.video-container {
    background-color: var(--video-background);
}

@media (max-width: 1024px) {
    .video-container {
        width: 100%;
    }
}

@media (min-width: 1025px) {
    .video-container {
        width: 80%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }
}

.video {
    width: 100%;
}

@media (max-width: 600px) {
    .video {
        min-height: var(--video-height-smartphone);
        max-height: var(--video-height-smartphone);
    }
}

/*tablet*/
@media (min-width: 600px) and (max-width: 1024px) {
    .video {
        min-height: var(--video-height-tablet);
        max-height: var(--video-height-tablet);
    }
}

/*desktop*/
@media (min-width: 1025px) {
    .video {
        flex-grow: 1;
    }
}


.video-controls {
    padding: 5px;
    color: white;
}

.video-controls button {
    margin-right: 10px;
    margin-bottom: 5px;
    border-color: black;
}

.video-controls .speed-active {
    background-color: green;
}