
.file-list {
    background-color: var(--gray-10);
    padding: 5px;
    overflow: auto;
    box-sizing: border-box;
    flex-grow: 1;
}

/*
@media (min-width: 992px) {
    .file-list {
        top: 40px;
        width: 200px;
    }
}

@media (min-width: 576px) and (max-width: 992px) {
    .file-list {
        right: 0px;
        height: var(--bottom-height-tablet);
    }
}

@media (max-width: 576px) {
    .file-list {
        right: 0px;
        height: var(--bottom-height-smartphone);
        ;
    }
}*/

.file-list .day .title {
    font-weight: bold;
}

.file-list .day .file {
    font-size: 90%;
    padding-left: 10px;
    cursor: pointer;
}

.file-list .day .file:hover {
    background-color: rgba(0, 0, 0, 0.1);
}
.file-list .selected{
    background-color: rgba(0, 0, 0, 0.1);
}
