.events-list {
    background-color: var(--gray-10);
    padding: 5px;
    overflow: auto;
    box-sizing: border-box;
    flex-grow: 1;

}

.events-list .event{
    font-size: 90%;
    padding-left: 10px;
    cursor: pointer;
}
.events-list .event:hover {
    background-color: rgba(0, 0, 0, 0.1);
}
.evensts-list .selected{
    background-color: rgba(0, 0, 0, 0.1);
}