:root {
    --gray-4: #444444;
    --gray-6: #666666;
    --gray-10: #cccccc;
    --video-background: #111;
    --video-height-smartphone: 230px;
    --video-height-tablet: 550px;
}

html, body {
    height: 100% !important;
}

#root {
    height: 100% !important;
    display: flex;
    flex-direction: column;
}

.tab-list {
    display: flex;
    align-items: center;
    padding: 5px;
}

/************************************/
.operations-list {
    background-color: var(--gray-6);
}

.operations-list .tab-list-item {
    color: white;
    padding: 0px 20px 0px 20px;
    cursor: pointer;
}

/*****************************/
.selected {
    text-decoration: underline;
    font-weight: bold
}

.disposition {
    flex-grow: 1;
    overflow: hidden;
    display: flex;
}

/*tablet e smartphone*/
@media (max-width: 1024px) {
    .disposition {

        flex-direction: column;
    }
}

/*desktop*/
@media (min-width: 1025px) {
    .disposition {
        flex-direction: row-reverse
    }
}