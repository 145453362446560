.event-image-container{
    background-color: var(--video-background);
}

@media (max-width: 1024px) {
    .event-image-container {
        width: 100%;
    }
}

@media (min-width: 1025px) {
    .event-image-container {
        width: 80%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }
}

.event-image {
    width: 100%;
}

/*smartphone*/
@media (max-width: 600px) {
    .event-image {
        min-height: var(--video-height-smartphone);
        max-height: var(--video-height-smartphone);
    }
    .event-image img {
        margin: 0 auto;
        max-height: var(--video-height-smartphone);
        max-width: 100%;
    }
    
}

/*tablet*/
@media (min-width: 600px) and (max-width: 1024px) {
    .event-image {
        min-height: var(--video-height-tablet);
        max-height: var(--video-height-tablet);
    }
    .event-image img {
        max-height: var(--video-height-tablet) !important;
    }
}

/*desktop*/
@media (min-width: 1025px) {
    .event-image {
        flex-grow: 1;
    }
}

