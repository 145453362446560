.camera-list {
    background-color: var(--gray-4);
}
.camera-list .tab-list-item {
    color: white;
    font-size: 120%;
    padding: 0px 20px 0px 20px;
    cursor: pointer;
}
.camera-list .commands {
    color: rgba(255, 255, 255, 0.5);
    padding: 0px 20px 0px 20px;
    flex-grow: 1;
    text-align: right;
    font-size: 70%;
}
.camera-list .commands a {
    color: rgba(255, 255, 255, 0.5);
    padding-left: 5px;
}
